.list-info {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: -40px;
        top: 2px;
        width: 25px;
        height: 25px;
        background-color: #FFC22C;
        border-radius: 50%;
    }
}

footer {
    position: relative;

    &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #003B7D;
        border-radius: 50% 50% 0 0/ 100% 100% 0 0;
        transform: scaleX(1.5);
    }
}